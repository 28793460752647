import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "../../components/footer"
import Fg1 from '../../images/e1.jpg'
import Head from "../../components/header"

const ExamplesPage = () => (
  <Layout>
    <Head pageTitle="導入事例 - 株式会社GAZIRU" pageLang="ja" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>導入事例</h4>
          <h5>株式会社 アプレ様</h5>
          <p>高級ブランド品の二次流通において現品とグレーディングレポートを対応付けて管理可能な「TALグレーディングレポート発行サービス」を提供しました。</p>

          <h6>ビジネス上の課題・ニーズ</h6>
          <p>高級ブランド品の二次流通では、商品の真贋ならびに品質状態の判別(以下では「真贋判定」といいます)が重要で、特別な知識を持った専門家により行われますが、鑑定書が付属された取引が少なく取引毎に鑑定が必要となるため、必要な鑑定工数に対する専門家の不足が課題となっています。また、鑑定書を発行しても現品と鑑定書がすり替わる可能性があるなど、鑑定書による取引が普及しづらい課題もあります。</p>
          <p>このような中、アプレ様では、真贋判定業務の効率化を図るとともに、自社が保有する高い真贋判定技術を生かした新たなサービスの提供を考えました。</p>

          <h6>ソリューション (解決策)</h6>
          <p>アプレ様では、自社の真贋基準を満たした高級ブランド品の状態を、ランクと定量的な数値で評価し、その結果を可視化したグレーディングレポートを発行しています。GAZIRU個体識別サービスを活用することにより、グレーディングレポートと商品の画像を関連づけた管理を可能とする、「TALグレーディングレポート発行サービス」を実現しました。</p><br />
          <img src={Fg1}></img><br />

          <br />

          <h5>株式会社 ダッドウェイ様</h5>
          <p>エルゴベビー・ベビーキャリア（日本限定新商品）の偽造品対策に採用されました。</p>
          <p>米国大手ベビー用品メーカー「Ergobaby（エルゴベビー）」の日本正規総代理店 株式会社ダッドウェイは エルゴベビー社と共同開発をした、日本限定の新機能を付加したベビーキャリアを発売。同製品の偽造品対策の一つとして、GAZIRU個体識別サービスをご利用いただいております。</p>

          <br />

          <h5>その他のお客様・業種・業界</h5>
          <p>百貨店のカタログから商品画像を読み込み、ECサイトへ誘導する仕組みの開発をしました。</p>
          <h6>導入業種・業界</h6>
          <p>電子部品、イメージングシステム、工業機能部品、ファスニング、自動車、素材・材料、技術商社ほか
          </p>

          <br />

          <h5>お見積り・お問い合わせ</h5>
          <p>GAZIRUのご利用料金はサービスの種類、ご利用になる機能によって異なります。</p>
             <p>お客様にとって最適な機能とご利用料金をご案内いたしますので、お気軽に以下のフォームからお問い合わせください。</p>

            <p><Link className="text-decoration-none" to="/notification/contact/ja">お問い合わせフォーム</Link></p>

            <br />
            <p><Link className="text-decoration-none" to="/">Home</Link></p>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default ExamplesPage
